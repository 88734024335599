/* Globsls */
import './globals/Globals';

/* Components */
import Routes from './Routes';

/* Styles */
import './scss/index.scss';

export default function App() {
  return (
    <div className="App">
      <Routes/>
    </div>
  );
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/* Components */
import SocialMedia from '../socialMedia/SocialMedia';

import './footer.scss';

export default class Footer extends Component {
  constructor() {
    super();
    this.state = { year: new Date(Date.now()).getUTCFullYear() }
    this.copyDate = React.createRef();
  }

  render() {
    return (
      <footer>
        <div className="row">
          <span className="copy">&#169; {this.state.year} Excessive Gaming, LLC - All Rights Reserved.</span>
          <span className="hide"><Link to="/termsandconditions">Terms &amp; Conditions</Link></span>
          <span className="hide"><Link to="/privacypolicy" className="privacyPolicy">Privacy Notice</Link></span>
        </div>
        <div className="row"><SocialMedia /></div>
      </footer>
    );
  }
}
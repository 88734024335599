import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";

import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Loader from './components/loader/Loader';

const About = lazy(() => import('./pages/about/About'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Events = lazy(() => import('./pages/events/Events'));
const Home = lazy(() => import('./pages/home/Home'));
//const Managment = lazy(() => import('./pages/teams/Management'));
const NewsList = lazy(() => import('./pages/news/NewsList'));
const NewsDetails = lazy(() => import('./pages/news/NewsDetails'));
const NotFound = lazy(() => import('./pages/notFound/NotFound'));
const Partners = lazy(() => import('./pages/partners/Partners'));
//const Teams = lazy(() => import('./pages/teams/Teams'));
const TeamsRoster = lazy(() => import('./pages/teams/TeamsRoster'));
const Terms = lazy(() => import('./pages/terms/Terms'));
//const Test = lazy(() => import('./pages/test/Test'));
//const Videos = lazy(() => import('./pages/videos/Videos'));

// const Admin = lazy(() => import('./pages/admin/Admin'));
// const AdminEvents = lazy(() => import('./pages/admin/AdminEvents'));
// const AdminMembers = lazy(() => import('./pages/admin/AdminMembers'));
// const AdminNews = lazy(() => import('./pages/admin/AdminNews'));
// const AdminPartners = lazy(() => import('./pages/admin/AdminPartners'));
// const AdminTeams = lazy(() => import('./pages/admin/AdminTeams'));

const Navigation = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='*' element={<NotFound/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/events' element={<Events/>}/>
          {/* <Route path='/managment' element={<Managment/>}/> */}
          <Route path='/news' element={<NewsList/>}/>
          <Route path='/news/:id' element={<NewsDetails/>}/>
          <Route path='/partners' element={<Partners/>}/>
          {/* <Route path='/teams' element={<Teams/>}/> */}
          <Route path='/teams/:teamCode' element={<TeamsRoster/>}/>
          {/* <Route path='/teams/:teamCode/:handle' element={<TeamsRosterMember/>}/> */}
          <Route path='/terms/:termName' element={<Terms/>}/>
          {/* <Route path='/videos' element={<Videos/>}/> */}
        </Routes>
      <Footer/>
    </React.Suspense>
  )
}

export default Navigation;

/*
  <Route exact path='/admin' component={Admin}/>
  <Route exact path='/admin/events' component={AdminEvents}/>
  <Route exact path='/admin/members' component={AdminMembers}/>
  <Route exact path='/admin/news' component={AdminNews}/>
  <Route exact path='/admin/partners' component={AdminPartners}/>
  <Route exact path='/admin/teams' component={AdminTeams}/>

  <Route exact path='/events' component={Events}/>
  <Route exact path='/events/:type' component={Events}/>
  <Route exact path='/profile' component={Profile}/>
  <Route exact path='/profile/view' component={Profile}/>
  <Route exact path='/profile/edit' component={Profile}/>
*/
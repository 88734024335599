import React from 'react';
//import { FaSpinner, FaCircleNotch, FaSync, FaCog, FaFan, FaCompass, FaSun, FaAtom } from 'react-icons/fa';

/* Styles */
import './loader.scss';

function Loader() {
  return (
    <div id="Loader">
      <div>Loading...</div>
    </div>
  );
}

export default Loader;
import React, { useState, useEffect, useRef } from 'react';
//import { FaUserCircle, FaUser, FaGavel, FaSignOutAlt } from 'react-icons/fa';
import { FaGavel, FaSignOutAlt } from 'react-icons/fa'; //FaUserCircle, FaUser, 
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
//import Axios from 'axios';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
//import { isMobile } from 'react-device-detect';

/* Components */
import SocialMedia from '../socialMedia/SocialMedia';

/* Stores */
//import { useTeamsStore } from '../../Store';

/* Images */
import Logo from '../../assets/images/XSVG_Secondary_Logo.png';

/* Styles */
import './header.scss'; 

function Header() {
  const SCROLL_LIMIT = 30;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const [teamsList, setTeamsList] = useState([]);
  const [showHamburger, setShowHanburger] = useState(true);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState();

  const headerRef = useRef();
  const mainLinksRef = useRef();
  const hamburgerIconRef = useRef();

  /* const teamsList = useTeamsStore((state) => state.teamsList);
  console.log('teamsList: ', teamsList); */

  useEffect(() => {
    let subscribe = true;
     
    if (subscribe) {
      window.addEventListener('scroll', scrollCheck);
      window.addEventListener('resize', showHamburgerIcon);
      scrollCheck(); 
      showHamburgerIcon();
    }

    return () => {
      subscribe = false;
      window.removeEventListener('scroll', scrollCheck);
      window.removeEventListener('resize', showHamburgerIcon);
    }
  });

  useEffect(() => {
    let loadingTeams = true
    
    if (loadingTeams) //getTeams();
    return () => loadingTeams = false;
  }, [])
  
  const addAdminLink = () => {
   //if (localStorage.getItem('currentUserIsAdmin') === 'true') {
      return <Link to="/admin" className="adminLink" title="Admin"><FaGavel /></Link>
    //}
  };

/*   const scrollChecked = () => {
    if (window.pageYOffset >= SCROLL_LIMIT) {
      headerRef.current.classList.add('scrolled');
    }
  } */

  const scrollCheck = () => {
    if (window.pageYOffset >= SCROLL_LIMIT) {
      headerRef.current.classList.add('scrolled');
    } else {
      headerRef.current.classList.remove('scrolled');
    }
  }

  const showHamburgerIcon = (header) => {
    if (window.innerWidth <= 700) {
      setShowHamburgerMenu(false);
      setShowHanburger(() => {
        mainLinksRef.current.style.visibility = 'hidden';
        setTimeout(() => {
          mainLinksRef.current.style.visibility = 'visible';
        }, 400);
        return true
      });
    } else {
      setShowHanburger(false);
      setShowHamburgerMenu(true);
      scrollCheck();
    }

    document.querySelector('#Modal').classList.add('hide');
  }

  const loginLink = () => {
    // console.log('isLoggedIn: ', isLoggedIn);
    // console.log('typeOf: ', typeof(isLoggedIn));
    return (isLoggedIn) ? loggedIn() : loggedOut();
  };
  //        <Link to="/profile/view" ref={loggedUser} className="hidden" title="Profile"><FaUserCircle /></Link>
  const loggedIn = () => {
    return (
      <li className="loggedIn">
        <SocialMedia />
        {addAdminLink()}
        <button className="logoutBtn" onClick={(e) => logout(e)} title="Logout"><FaSignOutAlt /></button> 
      </li>
    );
  };

  const logout = (evt) => {
    evt.preventDefault();
    /*Auth.signOut().then((res) => {
      localStorage.removeItem('currentUserId');
      localStorage.removeItem('currentUserIsAdmin');
      localStorage.removeItem('currentUserFirstName');
      localStorage.removeItem('currentUserHandle');
      window.location.assign('/');
    }).catch((error) => {
      console.log('logout Error: ', error);
    });*/
  }

  const loggedOut = () => {
    return (
      <li className="loggedOut">
        <SocialMedia />
        <button className="hidden" onClick={(e) => showLoginForm(e)}>Login</button>
      </li>
    );
  };

  const showLoginForm = (evt) => {
    /*evt.preventDefault(); 
    let modal = document.getElementById('Modal');
    modal.style.display = 'block';
    modal.querySelector('.loginForm').classList.remove('hide');
    modal.querySelector('.passwordForm').classList.add('hide');*/
    setIsLoggedIn(true);
    //return false;
  };

  
  /* const openDropDown = (e) => {
    e.preventDefault();
    let parentClassList = e.target.closest('.mainNavLink').classList;
    parentClassList.add('hover');
    parentClassList.add('active');
  }

  const closeDropDown = (e) => {
    e.preventDefault();
    let parentClassList = e.target.closest('.mainNavLink').classList;
    parentClassList.remove('hover');    

    setTimeout(() => {
      if (!parentClassList.contains('hover')) {
        parentClassList.remove('active'); 
      }
    }, 200);
  } */

  const handleShowHamburgerMenu = (e) => {
    e.preventDefault();

    let modal = document.querySelector('#Modal'),
        isHamburgerView = headerRef.current.classList.contains('hamburgerView');

    if (isHamburgerView) {
      setShowHamburgerMenu(() => {
        (!showHamburgerMenu) ? modal.classList.remove('hide') : modal.classList.add('hide');
        return !showHamburgerMenu;
      });
    } else {
      modal.classList.add('hide');
    }
  }

  const closeMenu = (e) => {
    e.preventDefault();
    handleShowHamburgerMenu(e);
  }

  return (
    <header className={showHamburger ? 'hamburgerView' : ''} ref={headerRef}>
      <div id="Header" className="headerContent">
        <div className="secondaryNav">
          <ul className="nav">
            <li className="hashtag">#CrashFam</li>
            {loginLink()}
          </ul>
        </div>  
        <div className="mainNav">
          <Link to="/"><img src={Logo} className="logo" alt="Logo" /></Link> 
          <ul ref={mainLinksRef} id="MainNavLinks" className={`nav ${showHamburgerMenu ? 'open' : ''}`} onClick={(e) => closeMenu(e)}>
            {(showHamburger ? 'hide' : '') ? 
              <li className="mainNavLink closeBtn"><Link to="" onClick={(e) => handleShowHamburgerMenu(e)}>
                <AiOutlineClose />Close Menu</Link>
              </li> : ''
            }           
            <li className="mainNavLink"><Link to="/about">About</Link></li>            
            <li className="mainNavLink"><Link to="/news">News</Link></li>
            <li className="mainNavLink"><Link to="/events">Events</Link></li>
            {/* <li className="mainNavLink dropNavLink">
              <Link to="/teams" className="dropLink" onMouseEnter={(e) => openDropDown(e)} onMouseLeave={(e) => closeDropDown(e)}>Teams</Link> 
              <div className="dropdown" onMouseEnter={(e) => openDropDown(e)} onMouseLeave={(e) => closeDropDown(e)}>
                {teamsList.map(team => <a href={`/teams/${team.code}`} key={team.id}>{team.team_name}</a>)}
              </div>             
            </li> */}
            <li className="mainNavLink"><Link to="/teams/strc">Creators</Link></li>
            <li className="mainNavLink"><Link to="/partners">Partners</Link></li>
            <li className="mainNavLink"><a href="https://www.xsvgg.com/shop" target="_blank" rel="noreferrer">Store</a></li>
            <li className="mainNavLink"><Link to="/contact">Contact</Link></li>
          </ul>
        </div>  
        <div id="Modal" className="hide"></div>
        <Link ref={hamburgerIconRef} id="Hamburger" className={!showHamburger ? 'hide' : ''} rel="noreferrer" onClick={(e) => handleShowHamburgerMenu(e)}>
          <span className="topBun"></span>
          <span className="meat"></span>
          <span className="bottomBun"></span>
        </Link>         
      </div>
    </header>
  )
}

export default Header;
import React from 'react';
import { FaTwitter, FaTwitch, FaYoutube, FaInstagram, FaDiscord } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

/* Styles */
import './socialMedia.scss'; 

function SocialMedia(props) {
  return (
    <ul className="socialMedia">
      <li>
        <a href="https://twitter.com/xsvgg" target="_blank" rel="noreferrer"><FaTwitter /></a>
      </li>
      <li>
        <a href="https://instagram.com/xsvgg" target="_blank" rel="noreferrer"><FaInstagram /></a>
      </li>
      <li>
        <a href="https://youtube.com/channel/UC5w1N4ILo2IKXXDxKg0iAHQ" className="youTubeIcon" target="_blank" rel="noreferrer">
          <FaYoutube />
        </a>
      </li>
      <li className="hide">
        <a href="https://twitch.tv/xsvgg" target="_blank" rel="noreferrer"><FaTwitch /></a>
        </li>
      <li>
        <a href="https://xsvgg.com/discord" target="_blank" rel="noreferrer"><FaDiscord /></a>
      </li>
      <li className="hide">
        <a href="mailto:info@xsvgg.com"><AiOutlineMail /></a>
      </li>
    </ul>
  )
}

export default SocialMedia;
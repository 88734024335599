import Axios from "axios";

global.apiUrl = process.env.REACT_APP_SERVER + ':' + process.env.REACT_APP_PORT_NUMBER + '/api/';

// Global React Variables
global.xsv = {
  streamers: ['alienking1970', 'Izzy_leo_hill', 'myksymz', 'zomb13_k1ll3r']
}

global.appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
  return script;
}

global.removeScript = (scriptToremove) => {
  let allsuspects=document.getElementsByTagName("script");
  for (let i=allsuspects.length; i>=0; i--) {
    if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null 
    && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1 ) {
      allsuspects[i].parentNode.removeChild(allsuspects[i])
    }    
  }
}

// Api Request
/**
* arg {
* actionName: (string) name of action
* params: (object) data that is being sent
* callback: (function) callback function to call
* }
*/

global.actionRequest = (args) => {
  //Axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + ((process.env.NODE_ENV === 'development') ? ':' + 3000 : '') + '/';
  //Axios.defaults.headers.common['Authorization'] = '96a4f7f5-05bf-4361-872c-094b2a85c6e2'; //AUTH_TOKEN;
  //Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  //Axios.defaults.timeout = (args.timeout !== undefined) ? args.timeout : 0;
  //Axios.defaults.headers.common['Content-Type'] = 'application/json';
  //Axios.defaults.headers.common['Accept'] = 'application/json';


  //Axios.defaults.headers.common['Set-Cookie'] = 'same-site-cookie=bar; SameSite=Lax';
  //Axios.defaults.headers.common['Set-Cookie'] = 'cross-site-cookie=foo; SameSite=None; Secure';

  let url = (args.actionName !== undefined) ? global.apiUrl + args.actionName : args.url;

  if (args.method === 'all') {
    Axios.all(url).then(Axios.spread((...response) => {
      args.callback.call(this, response, args);
    }));
  } else if (args.method === 'post') {
    return Axios.post(url, args.params);
  } else if (args.method === 'patch') {
    return Axios.patch(url, args.params);
  } else if (args.method === 'delete') {
    return Axios.delete(url, args.params);
  } else {
    return Axios.get(url, {params: args.params});
  }
}

// Uppercase First Letter
global.ucFirstLetter = (str) => {
  if (str !== undefined) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}

// Camelcase Word by Letter Indexed 
global.ccWord = (str, ccIdx) => {
  if (str !== undefined) {
    return str.charAt(ccIdx).toUpperCase() + str.slice(ccIdx + 1);
  }
}
 
// Format Date
// Note: date must be passed in as a string.
global.getFormattedDate = (date) => {
  let d = new Date(Date.parse(date)),
      month = d.getMonth() + 1,
      day = d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      formattedHours = (hours > 12) ? hours - 12 : hours,
      minutes = d.getMinutes(),
      formattedMinutes = (minutes <= 9) ? '0' + minutes : minutes,
      meridian = (hours >= 12) ? 'pm' : 'am'

  return month + '/' + day + '/' + year + ' ' + formattedHours + ':' + formattedMinutes + meridian;
};

global.getFullDate = (date) => {
  console.log('date: ', date);
  let dateSplit = date.split('-'),
      year = dateSplit[0],
      month = dateSplit[1],
      day = dateSplit[2];

      console.log('month: ', month);
      console.log('day: ', day);
      console.log('year: ', year);

  return global.getMonthName(month) + ' ' + global.getOrdinalNum(day) + ', ' + year;
};

global.getOrdinalDate = (date) => {
  let dateSplit = date.split('-'),
      month = dateSplit[1],
      day = dateSplit[2];

  return global.getMonthName(month) + ' ' + global.getOrdinalNum(day);
};

// get month name
global.getMonthName = (month) => {
  let months = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months[month-1];
};

global.getShortMonth = (month) => {
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dev'];
  return months[month-1];
};

global.getOrdinalNum = (number) => {
  let selector,
      day = (number.search(0) > 0) ? number : number.replace('0', '');

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return day + ['th', 'st', 'nd', 'rd', ''][selector];
};

global.getTimeSince = (date) => {
  let seconds = Math.floor((new Date() - Date.parse(date)) / 1000),
      interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }

  interval = Math.floor(seconds / 2592000);

  if (interval > 1) {
    return interval + " months";
  }

  interval = Math.floor(seconds / 86400);

  if (interval > 1) {
    return interval + " days";
  }

  interval = Math.floor(seconds / 3600);

  if (interval > 1) {
    return interval + " hours";
  }

  interval = Math.floor(seconds / 60);

  if (interval > 1) {
    return interval + " minutes";
  }

  return Math.floor(seconds) + " seconds";
}

// Check if Object is Empty
global.isObjEmpty = (obj) => {
  for(var key in obj) {
    if (obj.hasOwnProperty(key)) { return false; }
  }
  return true;
}

//check if localStorage is available
global.storageAvailable = (type) => {
  var storage;
  try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
  }
  catch(e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0
      );
  }
}

// remove empty items from array 
global.emptyFilter = (arr) => {
  arr.filter(el => { return el !== ''});
}

//check if obj is empty
global.isObjEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) { return false; }
  }
  return true;
}

global.validateEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

global.changeValueColor = (element) => {
  if (element.value !== '') {
    element.classList.add('hasValue');
  } else {
    element.classList.remove('hasValue');
  }
}

global.formInputAnimation = (form) => {
  let textInputs = form.querySelectorAll('input[type="text"]'),
      passInputs = form.querySelectorAll('input[type="password"]'),
      inputs = [...textInputs, ...passInputs];

      

  for(var i=0; i<inputs.length; i++) {
    inputs[i].addEventListener('focus', function(e) {
      e.target.parentElement.classList.add('focused');
    });
  
    inputs[i].addEventListener('blur', function(e) {
      let target = e.target;
      if (target.value === "" ) {
       target.classList.remove('filled');
       e.target.parentElement.classList.remove('focused');  
      } else {
       target.classList.add('filled');
      }
    });
  }
}

global.checkInputValues = (form) => {
  let inputs = form.querySelectorAll('input'),
      selects = form.querySelectorAll('select'),
      textareas = form.querySelectorAll('textarea'),
      formInputs =  [...inputs, ...selects, ...textareas];

  for (var i=0; i<formInputs.length; i++) {
    let formElement = formInputs[i],
        eventTrigger = (formElement.nodeName === 'INPUT' || formElement.nodeName === 'TEXTAREA') ? 'blur': 'change';

    if (!formElement.classList.contains('btn-primary')) {
      formElement.addEventListener(eventTrigger , (evt) => {
        evt.target.style.color = (evt.target.value !== '') ? '#0b85c8' : '#9a9a9a';
      });
    }
  }
}

export default global;